<template>
    <div class="customer-input-container">
        <div class="customer-input-container-top">
            <van-nav-bar :title="info.id > 0 ? '修改客户' : '新增客户'" fixed/>
        </div>
        <div class="customer-input-container-form">
            <van-cell-group>
                <van-field
                        v-model="info.title"
                        required
                        clearable
                        label="客户名称"
                        placeholder="请输入客户名称"
                />

                <van-field
                        v-model="info.address"
                        required
                        clearable
                        label="客户地址"
                        placeholder="请输入客户地址"
                />

            </van-cell-group>
        </div>
        <div class="customer-input-container-btn">
            <van-button type="info" icon="passed" size="large" @click="onSubmit"
                        style="position:fixed;bottom:0;"
                        text="提交"/>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'customer-input',
        props: ['node', 'param'],
        data() {
            return {
                info: this.param && this.param.id ? this.param : {
                    id: 0,
                    title: '',
                    address: '',
                }
            }
        },
        methods: {
            onSubmit() {
                let that = this;
                that.$api.customer.input(this.info).then(response => {
                    let res = response.data
                    if (res.code == 1) {
                        that.$notify({type: 'primary', message: res.msg});
                        // 独立弹出
                        if (that.node && that.node.id) {
                            that.$emit('onClose', that.node);
                            that.picker.search = true;
                        }
                        // 客户查询内弹出
                        if (that.param) {
                            if (that.param.id > 0) {
                                that.$emit('onChange', that.info);
                            } else {
                                that.$emit('onClose');
                            }
                        }
                    } else {
                        that.$notify({type: 'warning', message: res.msg})
                    }
                })
            }
        }
    }
</script>

<style scoped>

    .customer-input-container-form {
        position: fixed;
        width: 100%;
        top: 46px;
        bottom: 50px;
        overflow-y: scroll;
    }

</style>
